<template>
  <div class="page">
    <Head :headTitle="headTitle" />
    <div class="b-container">
      <div class="con-l">
        <div class="mod1 flex flex-v">
          <div class="b-pub-tt flex inc">
            <span class="bt">基本情况介绍</span>
          </div>
          <div class="nhjj">
            <div class="mySwipbox">
              <div class="text inner-container">
                <br />
                <p>
                  隆安县 ，隶属广西壮族自治区南宁市，位于广西壮族自治区西南部、右江下游两岸，地处东经107°21′～108°6′，北纬22°51′～23°21′，北与百色市平果市相连，西及西南连崇左市天等县、大新县、江州区、扶绥县，东南同本市西乡塘区及接壤，东北邻本市武鸣区，县境东西最大横距77千米，南北最大纵距56千米，总面积2306平方千米。
                </p>
                <p>
                  隆安县属南亚热带湿润季风气候，炎热多雨，冬短夏长
                </p>
                <p>
                  秦朝（前214年-前206年）属象郡地，唐武德五年（622年），从宣化县分设朗宁县时，为朗宁地，属邕州，明嘉靖十二年（1533年）四月，设置隆安县，属南宁府管辖，1958年12月，隆安县与武鸣县合并称武隆县。1959年5月10日，恢复隆安县建制。隆安县是大西南铁路、公路、水路的重要交通枢纽。
                </p>
                <!-- <p>
                 祁东县,隶属湖南省衡阳市,地处衡阳市西南部、湘江中游北岸,东西狭长,北高南低,总面积1872平方千米,下辖4个街道、17个镇、3个乡、1个管理办事处。根据第七次人口普查数据,截至2020年11月1日零时,祁东县常住人口为766920人。
                </p> -->
                <br />
              </div>
            </div>
            <ul class="mod1-gk">
              <li>
                <img src="../assets/images/wt3.png" class="icon" alt="" />
                <div class="info">
                  <h6 class="tit">总人口</h6>
                  <p class="num" style="color: #489ffe">41.75万人</p>
                </div>
              </li>
              <li>
                <img src="../assets/images/wt4.png" class="icon" alt="" />
                <div class="info">
                  <h6 class="tit">林地面积</h6>
                  <p class="num" style="color: #85b1ff">210.436万亩</p>
                </div>
              </li>
              <li>
                <img src="../assets/images/wt4.png" class="icon" alt="" />
                <div class="info">
                  <h6 class="tit">森林覆盖率</h6>
                  <p class="num" style="color: #90ffad">61.16%</p>
                </div>
              </li>
              <li>
                <img src="../assets/images/wt7.png" class="icon" alt="" />
                <div class="info">
                  <h6 class="tit">出生率</h6>
                  <p class="num" style="color: #ffc55a">6.24‰</p>
                </div>
              </li>
              <li>
                <img src="../assets/images/wt7.png" class="icon" alt="" />
                <div class="info">
                  <h6 class="tit">城镇居民可支配收入</h6>
                  <p class="num" style="color: #45f5ff">24458元</p>
                </div>
              </li>
              <li>
                <img src="../assets/images/wt7.png" class="icon" alt="" />
                <div class="info">
                  <h6 class="tit">农村居民可支配收入</h6>
                  <p class="num" style="color: #45f5ff">13876元</p>
                </div>
              </li>
            </ul>
          </div>
          <div class="b-pub-tt flex inc">
            <span class="bt">那桐“四月八”农具节</span>
          </div>
          <div class="swiper-container swiper mySwiper3">
            <div class="swiper-wrapper">
              <div class="swiper-slide swiper-slide1">
                <img
                  src="https://video-1316200666.cos.ap-guangzhou.myqcloud.com/lonan/activity/a.jpg"
                  alt=""
                />
              </div>
              <div class="swiper-slide swiper-slide1">
                <img src="https://video-1316200666.cos.ap-guangzhou.myqcloud.com/lonan/activity/b.jpg" alt="" />
              </div>
              <div class="swiper-slide swiper-slide1">
                <img src="https://video-1316200666.cos.ap-guangzhou.myqcloud.com/lonan/activity/c.jpg" alt="" />
              </div>
              <div class="swiper-slide swiper-slide1">
                <img src="https://video-1316200666.cos.ap-guangzhou.myqcloud.com/lonan/activity/d.jpg" alt="" />
              </div>
              <div class="swiper-slide swiper-slide1">
                <img
                  src="https://video-1316200666.cos.ap-guangzhou.myqcloud.com/lonan/activity/e.jpg"
                  alt=""
                />
              </div>
              <div class="swiper-slide swiper-slide1">
                <img
                  src="https://video-1316200666.cos.ap-guangzhou.myqcloud.com/lonan/activity/f.jpg"
                  alt=""
                />
              </div>
<!--              <div class="swiper-slide swiper-slide1">-->
<!--                <img-->
<!--                  src="https://video-1316200666.cos.ap-guangzhou.myqcloud.com/fujian/fuan/photo/muyunshezu.jpg"-->
<!--                  alt=""-->
<!--                />-->
<!--              </div>-->
<!--              <div class="swiper-slide swiper-slide1">-->
<!--                <img src="https://video-1316200666.cos.ap-guangzhou.myqcloud.com/fujian/fuan/photo/putaogou.jpg" alt="" />-->
<!--              </div>-->
<!--              <div class="swiper-slide swiper-slide1">-->
<!--                <img src="https://video-1316200666.cos.ap-guangzhou.myqcloud.com/fujian/fuan/photo/shifengshi.webp" alt="" />-->
<!--              </div>-->
<!--              <div class="swiper-slide swiper-slide1">-->
<!--                <img src="https://video-1316200666.cos.ap-guangzhou.myqcloud.com/fujian/fuan/photo/wenhuaguan.webp" alt="" />-->
<!--              </div>-->
            </div>
          </div>
        </div>
      </div>
      <div class="con-c flex flex-v relative">
        <div class="mod-cen mod3">
          <div class="cen-menu">
             <span class="lk">智慧城市</span>
            <span class="lk">数字乡村振兴</span>
            <span class="lk">智慧工业溯源平台</span>
            <span class="lk cur">智慧文旅</span>
          </div>
          <div class="cen-top cen-wr">
            <li>
              <h6 class="tit">2024地区生产总值 <p>(亿元)</p> </h6>
              <p class="num">120.69</p>
            </li>
            <!-- <li class="line"></li> -->
            <li>
              <h6 class="tit">消费品零售总额<p>(亿元)</p></h6>
              <p class="num">14.47</p>
            </li>
            <!-- <li class="line"></li> -->
            <li>
              <h6 class="tit">公路客运量<p>(万人)</p></h6>
              <p class="num">290.95</p>
            </li>
            <li>
              <h6 class="tit">年累计游客<p>(万人)</p></h6>
              <p class="num">501.4</p>
            </li>
            <!-- <li class="line"></li> -->
            <li>
              <h6 class="tit">月累计游客<p>(万人)</p></h6>
              <p class="num">48.17</p>
            </li>
            <!-- <li class="line"></li> -->
            <li>
              <h6 class="tit">人均消费<p>(元/人)</p></h6>
              <p class="num">766.02</p>
            </li>
          </div>
          <div class="mod3-map">
            <!-- <div class="yueyang_map"></div> -->
            <div class="swiper-container swiper-container1">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <img
                    src="https://video-1316200666.cos.ap-guangzhou.myqcloud.com/lonan/scene/1.jpg"
                    alt=""
                    class="small_img"
                  />
                </div>
                <div class="swiper-slide">
                  <img
                    src="https://video-1316200666.cos.ap-guangzhou.myqcloud.com/lonan/scene/2.jpg"
                    alt=""
                    class="big_img"
                  />
                </div>
                <div class="swiper-slide">
                  <img
                    src="https://video-1316200666.cos.ap-guangzhou.myqcloud.com/lonan/scene/3.jpg"
                    alt=""
                    class="small_img"
                  />
                </div>
                <div class="swiper-slide">
                  <img
                    src="https://video-1316200666.cos.ap-guangzhou.myqcloud.com/lonan/scene/4.jpg"
                    alt=""
                    class="big_img"
                  />
                </div>
                <div class="swiper-slide">
                  <img
                    src="https://video-1316200666.cos.ap-guangzhou.myqcloud.com/lonan/scene/5.jpg"
                    alt=""
                    class="small_img"
                  />
                </div>
<!--                <div class="swiper-slide">-->
<!--                  <img-->
<!--                    src="https://video-1316200666.cos.ap-guangzhou.myqcloud.com/kashi/jinqu/6.jpg"-->
<!--                    alt=""-->
<!--                    class="big_img"-->
<!--                  />-->
<!--                </div>-->

<!--                <div class="swiper-slide">-->
<!--                  <img-->
<!--                    src="https://video-1316200666.cos.ap-guangzhou.myqcloud.com/kashi/jinqu/7.jpg"-->
<!--                    alt=""-->
<!--                    class="big_img"-->
<!--                  />-->
<!--                </div>-->
<!--                <div class="swiper-slide">-->
<!--                  <img-->
<!--                    src="https://video-1316200666.cos.ap-guangzhou.myqcloud.com/kashi/jinqu/8.jpg"-->
<!--                    alt=""-->
<!--                    class="small_img"-->
<!--                  />-->
<!--                </div>-->
<!--                <div class="swiper-slide">-->
<!--                  <img-->
<!--                    src="https://video-1316200666.cos.ap-guangzhou.myqcloud.com/kashi/jinqu/9.jpg"-->
<!--                    alt=""-->
<!--                    class="big_img"-->
<!--                  />-->
<!--                </div>-->
<!--                <div class="swiper-slide">-->
<!--                  <img-->
<!--                    src="https://video-1316200666.cos.ap-guangzhou.myqcloud.com/kashi/jinqu/10.jpg"-->
<!--                    alt=""-->
<!--                    class="small_img"-->
<!--                  />-->
<!--                </div>-->
              </div>
            </div>
          </div>
          <ul class="mod3-bot">
            <li>
              <img src="../assets/images/wlv_17.png" class="icon" alt="" />
              <span>景区景点</span>
            </li>
            <!-- <li>
              <img src="../assets/images/wlv_18.png" class="icon" alt="" />
              <span>交通接驳</span>
            </li> -->
            <li>
              <img src="../assets/images/wlv_19.png" class="icon" alt="" />
              <span>住宿</span>
            </li>
            <li>
              <img src="../assets/images/wlv_20.png" class="icon" alt="" />
              <span>餐饮</span>
            </li>
            <li>
              <img src="../assets/images/wlv_21.png" class="icon" alt="" />
              <span>公共服务</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="con-r flex flex-v">
        <div class="mod4 flex-1 flex flex-v">
          <div class="b-pub-tt flex inc">
            <span class="bt">著名景点</span>
          </div>
          <div class="flex flex-pack-justify flex-1">
            <div class="detail">
              <div class="col col1">
                <div class="b-smtit">
                  <span class="bt b-smtit1 b-smtit2">龙虎山景区</span>
                </div>
                <div>
                  <div class="swiper-container swiper-container2">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide">
                        <img src="https://video-1316200666.cos.ap-guangzhou.myqcloud.com/lonan/scene/1.jpg" alt="" />
                      </div>
                      <div class="swiper-slide">
                        <img src="https://video-1316200666.cos.ap-guangzhou.myqcloud.com/lonan/scene/1.jpg" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col col2">
                <div class="b-smtit">
                  <span class="bt b-smtit1">隆安右江</span>
                </div>
                <div>
                  <div class="swiper-container swiper-container5">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide">
                        <img
                          src="https://video-1316200666.cos.ap-guangzhou.myqcloud.com/lonan/scene/2.jpg"
                          alt=""
                        />
                      </div>
                      <div class="swiper-slide">
                        <img
                          src="https://video-1316200666.cos.ap-guangzhou.myqcloud.com/lonan/scene/2.jpg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col col3">
                <div class="b-smtit">
                  <span class="bt b-smtit1 b-smtit2">岜岑瀑布</span>
                </div>
                <div>
                  <div class="swiper-container swiper-container9">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide">
                        <img src="https://video-1316200666.cos.ap-guangzhou.myqcloud.com/lonan/scene/3.jpg" alt="" />
                      </div>
                      <div class="swiper-slide">
                        <img src="https://video-1316200666.cos.ap-guangzhou.myqcloud.com/lonan/scene/3.jpg" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col col4">
                <div class="b-smtit">
                  <span class="bt b-smtit1">白鹤岩</span>
                </div>
                <div>
                  <div class="swiper-container swiper-container3">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide">
                        <img src="https://video-1316200666.cos.ap-guangzhou.myqcloud.com/lonan/scene/4.jpg" alt="" />
                      </div>
                      <div class="swiper-slide">
                        <img src="https://video-1316200666.cos.ap-guangzhou.myqcloud.com/lonan/scene/4.jpg" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col col5">
                <div class="b-smtit">
                  <span class="bt b-smtit1 b-smtit2">更望湖</span>
                </div>
                <div>
                  <div class="swiper-container swiper-container4">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide">
                        <img src="https://video-1316200666.cos.ap-guangzhou.myqcloud.com/lonan/scene/5.jpg" alt="" />
                      </div>
                      <div class="swiper-slide">
                        <img src="https://video-1316200666.cos.ap-guangzhou.myqcloud.com/lonan/scene/5.jpg" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col col6">
                <div class="b-smtit">
                  <span class="bt b-smtit1">榜山文塔</span>
                </div>
                <div>
                  <div class="swiper-container swiper-container7">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide">
                        <img
                          src="https://video-1316200666.cos.ap-guangzhou.myqcloud.com/lonan/scene/6.jpg"
                          alt=""
                        />
                      </div>
                      <div class="swiper-slide">
                        <img
                          src="https://video-1316200666.cos.ap-guangzhou.myqcloud.com/lonan/scene/6.jpg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col col7">
                <div class="b-smtit">
                  <span class="bt b-smtit1 b-smtit2">仙缘谷</span>
                </div>
                <div>
                  <div class="swiper-container swiper-container8">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide">
                        <img src="https://video-1316200666.cos.ap-guangzhou.myqcloud.com/lonan/scene/7.jpg" alt="" />
                      </div>
                      <div class="swiper-slide">
                        <img src="https://video-1316200666.cos.ap-guangzhou.myqcloud.com/lonan/scene/7.jpg" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col col8">
                <div class="b-smtit">
                  <span class="bt b-smtit1">周氏宗祠</span>
                </div>
                <div>
                  <div class="swiper-container swiper-container6">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide">
                        <img
                          src="https://video-1316200666.cos.ap-guangzhou.myqcloud.com/lonan/scene/8.jpg"
                          alt=""
                        />
                      </div>
                      <div class="swiper-slide">
                        <img
                          src="https://video-1316200666.cos.ap-guangzhou.myqcloud.com/lonan/scene/8.jpg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col col9">
                <div class="b-smtit">
                  <span class="bt b-smtit1 b-smtit2">雁江古镇</span>
                </div>
                <div>
                  <div class="swiper-container swiper-container10">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide">
                        <img
                          src="https://video-1316200666.cos.ap-guangzhou.myqcloud.com/lonan/scene/9.jpg"
                          alt=""
                        />
                      </div>
                      <div class="swiper-slide">
                        <img
                          src="https://video-1316200666.cos.ap-guangzhou.myqcloud.com/lonan/scene/9.jpg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col col10">
                <div class="b-smtit">
                  <span class="bt b-smtit1">峨山</span>
                </div>
                <div>
                  <div class="swiper-container swiper-container11">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide">
                        <img src="https://video-1316200666.cos.ap-guangzhou.myqcloud.com/lonan/scene/10.jpg" alt="" />
                      </div>
                      <div class="swiper-slide">
                        <img src="https://video-1316200666.cos.ap-guangzhou.myqcloud.com/lonan/scene/10.jpg" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Head from "@/components/head_big.vue";
let Echarts = require("echarts/lib/echarts");
// import { loopShowTooltip } from "../assets/js/tooltip-auto-show-vue.js";
import Swiper from "swiper";
import "swiper/dist/css/swiper.min.css";
import "swiper/dist/js/swiper.js";
export default {
  data() {
    return {
      headTitle: "",
      // 月游客
      mVisitor: "",
      // 人均消费
      personCon: "",
      // 年度游客
      yVisitor: "",
      dataList: [
        {
          title: "常驻人口",
          content: "10万",
        },
        {
          title: "土地总面积",
          content: "56.18平方千米",
        },
        {
          title: "水域面积",
          content: "13.78平方公里",
        },
        {
          title: "湖岸线总长",
          content: "28.2公里",
        },
        {
          title: "年平均气温",
          content: "16度-22度",
        },
        {
          title: "年平均湿度",
          content: "79%",
        },
      ],
      swiper1: [],
      swiper2: [],
      swiper3: [],
      swiper4: [],
      swiper5: [],
      swiper6: [],
      swiper7: [],
      swiper8: [],
      swiper9: [],
      swiper10: [],
      swiper11: [],
      mySwiper1: [],
      mySwiper2: [],
    };
  },
  components: {
    Head,
  },
  computed: {},
  created() {
    console.log("document.body.clientWidth", document.body.clientWidth);
    console.log("window.screen.width", window.screen.width);
  },
  mounted() {
    // 获取智慧文旅的信息
    this.getwenlvData();
    this.swiper1 = new Swiper(".swiper-container.swiper-container1", {
      lazyLoading: true,
      lazyLoadingInPrevNext: true,
      lazyLoadingInPrevNextAmount: 2,
      speed: 3000,
      slidesPerView: 1,
      spaceBetween: -10,
      slidesPerColumn: 1,
      autoplay: 10000,
      loop: true,
      centeredSlides: true,
      slidesOffsetAfter: 100,
    });
    this.swiper2 = new Swiper(".swiper-container.swiper-container2", {
      effect: "flip",
      flip: {
        slideShadows: true,
        limitRotation: true,
      },
      speed: 3000,
      loop: true,
      autoplay: true,
    });
    this.swiper3 = new Swiper(".swiper-container.swiper-container3", {
      lazyLoading: true,
      lazyLoadingInPrevNext: true,
      lazyLoadingInPrevNextAmount: 2,
      effect: "flip",
      flip: {
        slideShadows: true,
        limitRotation: true,
      },
      speed: 3000,
      loop: true,
      autoplay: true,
    });
    this.swiper4 = new Swiper(".swiper-container.swiper-container4", {
      lazyLoading: true,
      lazyLoadingInPrevNext: true,
      lazyLoadingInPrevNextAmount: 2,
      effect: "flip",
      flip: {
        slideShadows: true,
        limitRotation: true,
      },
      speed: 3000,
      loop: true,
      autoplay: true,
    });
    this.swiper5 = new Swiper(".swiper-container.swiper-container5", {
      lazyLoading: true,
      lazyLoadingInPrevNext: true,
      lazyLoadingInPrevNextAmount: 2,
      effect: "flip",
      flip: {
        slideShadows: true,
        limitRotation: true,
      },
      speed: 4000,
      loop: true,
      autoplay: true,
    });
    this.swiper6 = new Swiper(".swiper-container.swiper-container6", {
      lazyLoading: true,
      lazyLoadingInPrevNext: true,
      lazyLoadingInPrevNextAmount: 2,
      effect: "flip",
      flip: {
        slideShadows: true,
        limitRotation: true,
      },
      speed: 4000,
      loop: true,
      autoplay: true,
    });
    this.swiper7 = new Swiper(".swiper-container.swiper-container7", {
      lazyLoading: true,
      lazyLoadingInPrevNext: true,
      lazyLoadingInPrevNextAmount: 2,
      effect: "flip",
      flip: {
        slideShadows: true,
        limitRotation: true,
      },
      speed: 4000,
      loop: true,
      autoplay: true,
    });
    this.swiper8 = new Swiper(".swiper-container.swiper-container8", {
      lazyLoading: true,
      lazyLoadingInPrevNext: true,
      lazyLoadingInPrevNextAmount: 2,
      effect: "flip",
      flip: {
        slideShadows: true,
        limitRotation: true,
      },
      speed: 4000,
      loop: true,
      autoplay: true,
    });
    this.swiper9 = new Swiper(".swiper-container.swiper-container9", {
      lazyLoading: true,
      lazyLoadingInPrevNext: true,
      lazyLoadingInPrevNextAmount: 2,
      effect: "flip",
      flip: {
        slideShadows: true,
        limitRotation: true,
      },
      speed: 4000,
      loop: true,
      autoplay: true,
    });
    this.swiper10 = new Swiper(".swiper-container.swiper-container10", {
      lazyLoading: true,
      lazyLoadingInPrevNext: true,
      lazyLoadingInPrevNextAmount: 2,
      effect: "flip",
      flip: {
        slideShadows: true,
        limitRotation: true,
      },
      speed: 4000,
      loop: true,
      autoplay: true,
    });
    this.swiper11 = new Swiper(".swiper-container.swiper-container11", {
      lazyLoading: true,
      lazyLoadingInPrevNext: true,
      lazyLoadingInPrevNextAmount: 2,
      effect: "flip",
      flip: {
        slideShadows: true,
        limitRotation: true,
      },
      speed: 4000,
      loop: true,
      autoplay: true,
    });
    this.mySwiper2 = new Swiper(".swiper-container.mySwiper2", {
      lazyLoading: true,
      lazyLoadingInPrevNext: true,
      lazyLoadingInPrevNextAmount: 2,
      autoplay: {
        // 自动滑动
        stopOnLastSlide: false,
        delay: 10000, //5秒切换一次
        disableOnInteraction: false,
      },
      autoplay: 10000,
      centeredSlides: true,
      direction: "vertical",
      grabCursor: true,
      autoplayDisableOnInteraction: false,
      // mousewheelControl:true,
      autoHeight: true,
      observer: true,
      observeParents: true,
      speed: 5000,
      loop: true
    });
    this.mySwiper3 = new Swiper(".swiper-container.mySwiper3", {
      lazyLoading: true,
      lazyLoadingInPrevNext: true,
      lazyLoadingInPrevNextAmount: 2,
      effect: "coverflow",
      slidesPerView: 3,
      centeredSlides: true,
      coverflow: {
        rotate: 30,
        stretch: 10,
        depth: 60,
        modifier: 2,
        slideShadows: true,
      },
      speed: 3000,
      autoplay: true,
      loop: true
    });
    window.addEventListener("resize", () => {
      // this.chart1.resize();
      // this.chart2.resize();
    });
  },

  methods: {
    // 获取智慧文旅的信息
    getwenlvData() {
      const res = axios.get("/10kszhwl.json").then(
        (res) => {
          console.log("请求成功了", res.data);
          this.headTitle= res.data.bigTitle
          this.mVisitor = res.data.mVisitor;
          this.personCon = res.data.personCon;
          this.yVisitor = res.data.yVisitor;
        },
        (err) => {
          console.log("请求失败了", err.message);
        }
      );
    },
  },
};
</script>

<style scoped>
.page {
  background: url(../assets/images/wlv_221.jpg) no-repeat center;
  background-size: 100% 100%;
  background-color: #0a224b;
}

@media screen and (max-width: 3840px) {
  .mod1 .nhjj .mySwiper2 {
    width: 100%;
    height: 35%;
  }
  .mod1 .nhjj .mySwiper2 .swiper-slide2 {
    font-size: 48px;
    height: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 30px;
    font-family: microsoft yahei;
  }
  .mySwipbox {
    height: 50%;
    overflow: hidden;
  }
  .mySwipbox .text {
    width: 100%;
    height: 100%;
    font-size: 42px;
    color: #fff;
    letter-spacing: 2px;
    font-family: SimSun;
  }
  .mySwipbox .text p {
    text-indent: 2em;
    font-size: 42px;
    font-family: SimSun;
  }
  .mod1-gk {
    /* margin-top: 5%; */
    width: 100%;
    /* height: 20%; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /* align-items: center; */
  }
  .mod1-gk li {
    margin-top: 2%;
    width: 33%;
    display: flex;
    align-items: center;
  }
  .mod4 .col .swiper-container {
    /* margin-top: 12%; */
    width: 90%;
    height: 50%;
  }
  .mod4 .detail .swiper-container .swiper-slide {
    border: 2px solid #15386e;
    border-radius: 10px;
    width: 100%;
    height: 50%;
  }
  .mod4 .detail .swiper-container .swiper-slide img {
    width: 100%;
    height: 140px;
    border-radius: 10px;
  }
}
@media screen and (max-width: 2720px) {
  .mod1 .nhjj .mySwiper2 {
    width: 100%;
    height: 35%;
  }
  .mod1 .nhjj .mySwiper2 .swiper-slide2 {
    font-size: 48px;
    height: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 30px;
    font-family: microsoft yahei;
    /* line-height: 1.8; */
  }

  .swiper-slide2 .text {
  }
  .mod1-gk {
    /* margin-top: 10%; */
    width: 100%;
    /* height: 20%; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /* align-items: center; */
  }
  .mod1-gk li {
    margin-bottom: 5%;
    width: 33%;
    display: flex;
    align-items: center;
    /* display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 20px; */
  }
  .mod4 .col .swiper-container {
    margin-top: 6%;
    width: 100%;
    height: 150%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .mod4 .detail .swiper-container .swiper-slide {
    border: 2px solid #15386e;
    border-radius: 10px;
    width: 100%;
    height: 100%;
  }
  .mod4 .detail .swiper-container .swiper-slide img {
    width: 100%;
    height: 200px;
    border-radius: 10px;
  }
}

.mod1 {
  height: 100%;
}
.mod1 .swiper {
  width: 100%;
  margin-top: 2.5%;
}
.mod1 .inc,
.mod4 .inc {
  height: 5%;
}
.mod1 .nhjj {
  height: 70%;
  width: 100%;
}

.mod1-gk .icon {
  width: 120px;
  height: 120px;
}
.mod1-gk .info {
  flex: 1;
  overflow: hidden;
}
.mod1-gk .tit {
  font-size: 20px;
  color: #fff;
}
.mod1-gk .num {
  margin-top: 2%;
  font-size: 26px;
  color: #fff;
  font-family: "YouSheBiaoTiHei";
}
.mySwiper2 .swiper-slide1 img {
  border-radius: 50%;
  box-shadow: 0px 10px 10px 10px #8f8282;
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  height: 60%;
  object-fit: cover;
}

.mod1 .mySwiper3 {
  height: 20%;
  /* box-sizing: border-box; */
  /* padding: 10px 0; */
}
.mod1 .mySwiper3 .swiper-slide {
  width: 25%;
}
.mod1 .mySwiper3 .swiper-slide img {
  width: 100%;
  /* height: 80%; */
}
.mod1 .mySwiper3 .swiper-wrapper {
  height: auto;
}
.mod2 {
  height: 55vh;
}
.mod2 .list {
  height: 55vh;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.mod2 .list .item {
  width: 48%;
  height: 22.5vh;
  height: calc(50% - 15px);
}
.mod2 .list .item:nth-child(1),
.mod2 .list .item:nth-child(2) {
  margin-bottom: 20px;
}
.mod2 .list .chart {
  width: 100%;
  height: calc(100% - 50px);
}

.mod2 .fenbu {
  width: 100%;
  height: calc(100% - 50px);
}

.con-c {
  position: relative;
}
/* 祁东 小于2560 */
@media screen and (max-width: 2560px) {
  .con-c .mod3 .cen-wr{
  margin-top: 10%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
}
/* 报业 大于3840 */
@media screen and (min-width: 3840px) {
  .con-c .mod3 .cen-wr{
  /* margin-top: 5%; */
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
}

.con-c .mod3 .cen-wr li{
  margin-top: 3%;
  width: 30%;
}
.con-c .mod3 .cen-wr li p{
  font-size: 26px;
  color: #fff;
  font-family: "PangMenZhengDao";
  margin-top: 5px;
}
.mod3-bot {
  display: flex;
  justify-content: space-around;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 120px;
  padding: 0 4%;
  z-index: 10;
}
.mod3-bot li {
  display: flex;
  align-items: center;
}
.mod3-bot .icon {
  width: 44px;
  margin-right: 16px;
}
.mod3-bot li span {
  font-size: 26px;
  color: #fff;
  font-family: "RuiZiZhenYan";
  letter-spacing: 3px;
}

.mod3-map {
  position: absolute;
  width: calc(100% + 300px);
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  margin-left: -150px;
}
.mod3-map .yueyang_map {
  opacity: 0.6;
  margin: 10% auto;
  width: 80%;
  height: 80%;
  background: url(../assets/images/qdmap.png) no-repeat center;
  background-size: 100% 100%;
}
.mod3-map .swiper-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -40%);
  width: 100%;
  height: 60%;
  /* background-color: #15386e; */
  overflow: hidden;
  /* margin-top: 50px; */
  width: 80%;
}
.mod3-map .swiper-container .swiper-wrapper {
  margin: 0 auto;
}
.mod3-map .swiper-container .swiper-slide {
  position: relative;
  width: 80%;
  text-align: center;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  transition: 300ms;
}
.mod3-map .swiper-container .swiper-slide .text {
  width: 200px;
  height: 300px;
  position: absolute;
  top: 400px;
  right: 230px;
}
.mod3-map .swiper-container .swiper-slide .big_img {
  width: 95%;
  height: 70%;
  image-rendering: optimizeQuality;
  border: 5px solid #8f8282;
  border-radius: 120px 120px 120px 120px;
}
.mod3-map .swiper-container .swiper-slide .small_img {
  width: 95%;
  height: 70%;
  image-rendering: optimizeQuality;
  border: 2px solid #15386e;
  border-radius: 120px 120px 120px 120px;
}

.mod4 {
  height: 100%;
}
.mod4 .detail {
  width: 95%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.mod4 .col {
  width: 50%;
  height: 20%;
}
/* .mod4 .col .swiper-container {
  width: 50%;
  height: 50%;
} */
/* .mod4 .col .swiper-container {
  margin-top: 6%;
  width: 100%;
  height: 60%;
} */

.mod4 .col .b-smtit1 {
  width: 200px;
  display: block;
  margin: 0 auto;
}
.mod4 .col .b-smtit2 {
  width: 200px;
  color: #e5528b;
  display: block;
  margin: 0 auto;
}
.mod4 .col1 .bar-list .font1,
.mod4 .col2 .bar-list .font1 {
  display: flex;
  justify-content: space-around;
}
.mod4 .list {
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: repeat(3, auto);
  grid-template-rows: repeat(3, auto);
  grid-gap: 15px;
}
.mod4 .list .item {
  position: relative; /* width: 185px;height: 160px; */ /* margin:0 0 25px 15px; */
}
.mod4 .list .title {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  background-color: #15386e;
  padding: 5px 5px;
  line-height: 24px;
  text-align: center;
  font-size: 14px;
  color: #fff;
}

/* .mod5 {
  height: 14vh;
}
.mod5 .b-pub-tt {
  margin-bottom: 10px;
}
.mod5 .list {
  display: flex;
  justify-content: space-between;
  padding-right: 40px;
}
.mod5 .list li {
  display: flex;
  align-items: center;
}
.mod5 .list .xh {
  width: 100px;
  margin-right: 5px;
  height: 100px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "PangMenZhengDao";
  font-size: 28px;
  color: #fff;
}
.mod5 .list .info {
  flex: 1;
  overflow: hidden;
}
.mod5 .list .tit {
  font-size: 22px;
  color: #fff;
}
.mod5 .list .num {
  font-size: 26px;
  color: #fff;
  font-family: "PangMenZhengDao";
  margin-top: 5px;
}

.mod5 .list .item1 .xh {
  background-image: url(../assets/images/wlv_13.png);
}
.mod5 .list .item2 .xh {
  background-image: url(../assets/images/wlv_14.png);
}
.mod5 .list .item3 .xh {
  background-image: url(../assets/images/wlv_15.png);
}
.mod5 .list .item4 .xh {
  background-image: url(../assets/images/wlv_16.png);
}
.mod5 .list .item5 .xh {
  background-image: url(../assets/images/wlv_23.png);
} */

.mod6 {
  height: 30%;
}
.mod6 .col {
  width: 46%;
}
.mod6 col .bar-list .smbox .font1 {
  display: flex;
  justify-content: space-around;
}
.inner-container {
  animation: myMove 20s linear infinite;
  animation-fill-mode: forwards;
}

@keyframes myMove {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-300px);
  }
}
</style>
